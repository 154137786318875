'use client';

import { useClientConfig } from '@spikemark/core';
import { useSize } from '@spikemark/shared-hooks';
import classNames from 'classnames';
import Image, { ImageLoaderProps, ImageProps } from 'next/image';
import { FC, ReactEventHandler, useCallback, useRef, useState } from 'react';
import { LoadingSpinner } from './loading-spinner';

type Gravity = 'n' | 's' | 'e' | 'w' | 'faces';

export const useCloudinaryImageLoader = ({
  aspectRatio = 1,
  gravity,
}: { aspectRatio?: number; gravity?: Gravity } = {}) => {
  const { cloudinary } = useClientConfig();
  const cloudinaryImageLoader = useCallback(
    ({ src, width, quality = 90 }: ImageLoaderProps) => {
      const url = encodeURI(src.startsWith('http') ? src : `${cloudinary.basePath}${src}`);
      return `${url}?tx=f_jpg,w_${width},ar_${aspectRatio.toFixed(1)},q_${quality},c_fill${gravity ? `,g_${gravity}` : ''}`;
    },
    [cloudinary, aspectRatio]
  );
  return cloudinaryImageLoader;
};

export const CloudinaryImage: FC<
  ImageProps & {
    gravity?: Gravity;
  }
> = ({ className, width, height, fill, gravity, ...props }) => {
  const container = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const size = useSize(container);
  const cloudinaryImageLoader = useCloudinaryImageLoader({
    aspectRatio: size.width > 0 ? size.width / size.height : 1,
    gravity,
  });
  const handleLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);
  const handleError = useCallback<ReactEventHandler<HTMLImageElement>>(() => {
    setIsLoaded(true);
    setHasError(true);
  }, []);

  return (
    <div
      className={classNames('relative w-full h-full overflow-clip bg-coolgray-lightest', className)}
      ref={container}
      style={{ width, height }}
    >
      {size && size.width > 0 ? (
        <Image
          {...props}
          loader={cloudinaryImageLoader}
          width={width ?? size.width}
          height={height ?? size.height}
          className={classNames(
            'object-cover object-center w-full h-full transition-opacity duration-300',
            {
              'opacity-0': !isLoaded,
              'opacity-1': isLoaded,
              invisible: hasError,
            }
          )}
          onLoad={handleLoad}
          onError={handleError}
        />
      ) : null}
      {/* LoadingSpinner is 148 x 36 px */}
      {!isLoaded && (
        <div className="absolute top-[50%] left-[50%] ml-[-74px] mt-[-18px] -z-1">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};
