import(/* webpackMode: "eager", webpackExports: ["PreviewLeaderboardClient"] */ "/app/apps/web/components/tables/leaderboard/preview/preview-leaderboard-client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TournamentPreviewBody"] */ "/app/apps/web/components/tables/tournament/preview/tournament-preview-body.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["YouTubePlayer"] */ "/app/apps/web/components/youtube-player.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SchoolField"] */ "/app/apps/web/fields/school-field.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TournamentField"] */ "/app/apps/web/fields/tournament-field.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/packages/core/src/cache/client-cache-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/core/src/config/client-config-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/components/avatar.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/components/card.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/components/cloudinary-image.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/components/cloudinary-video.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/components/mascot.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel","CarouselContent","CarouselItem","CarouselPrevious","CarouselNext"] */ "/app/packages/ui/components/ui/carousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/components/ui/tabs.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/components/ui/tooltip.tsx");
