import { Check } from 'lucide-react';
import { CommandItem } from './command';
import { cn } from '../../utils/cn';

export type ComboboxOption<TDatum = any> = string | { label: string; value: string; data?: TDatum };

export function getOptionLabel(option: ComboboxOption): string {
  return typeof option === 'string' ? option : option.label;
}

export function getOptionValue(option: ComboboxOption): string {
  return typeof option === 'string' ? option : option.value;
}

export const optionsAreEquivalent = (a: ComboboxOption, b: ComboboxOption) => {
  return getOptionValue(a).toLowerCase() === getOptionValue(b).toLowerCase();
};

export const findOptionByValue = (options: ComboboxOption[], value: string) => {
  return options.find((option) => optionsAreEquivalent(option, value));
};

export function ComboboxOptions<TDatum = never>({
  options,
  value,
  onChange,
  onClose,
}: {
  options: ComboboxOption<TDatum>[];
  value?: string;
  onChange?: (value: string, item: TDatum) => void;
  onClose: () => void;
}) {
  return (
    <>
      {options.map((option) => (
        <CommandItem
          key={getOptionValue(option)}
          value={getOptionValue(option)}
          onSelect={(currentValue) => {
            const nextValue =
              value && optionsAreEquivalent(currentValue, value) ? '' : currentValue;
            const option = findOptionByValue(options, nextValue);

            if (option) {
              onChange?.(
                getOptionValue(option),
                typeof option === 'object' ? option.data : undefined
              );
            }

            onClose();
          }}
          data-testid="combobox-option"
        >
          <Check
            className={cn(
              'mr-2 h-4 w-4',
              value && optionsAreEquivalent(option, value) ? 'opacity-100' : 'opacity-0'
            )}
          />
          {getOptionLabel(option)}
        </CommandItem>
      ))}
    </>
  );
}
